import React from 'react';
import cx from '../../utils/classnames';
// TODOs: Kindly update this once the icon components are implemented
import { LeftIcon, RightIcon } from './navigationIcon';
import styles from './navigationButton.module.scss';

export type Props = {
  /** Making component disabled */
  isDisabled?: boolean;

  /** Setting the aria-label property for accessibility. */
  ariaLabel?: string;

  /** Custom style for wrapper */
  className?: string;

  /** Navigation button direction */
  direction?: 'left' | 'right';

  /** Class type of the navigation button component */
  navigationType?: 'light' | 'dark' | 'simple';

  /** Button component behavioral type */
  type?: 'button' | 'submit' | 'reset';

  /** Function to called upon button click */
  onClick: () => void;
};

export default function NavigationButton(props: Props) {
  const { isDisabled, ariaLabel, className, navigationType, direction, ...rest } = props;

  const disabledStyle = isDisabled
    ? navigationType === 'simple'
      ? styles.disabledSimple
      : styles.disabled
    : styles.hover;
  return (
    <button
      aria-label={ariaLabel}
      className={cx(styles.button, className, styles[`navigation${navigationType}`], disabledStyle)}
      data-testid={`navigationButton-${direction}`}
      disabled={isDisabled}
      {...rest}
    >
      <span key="icon" className={styles.icon}>
        {direction === 'left' ? <LeftIcon /> : <RightIcon />}
      </span>
    </button>
  );
}

NavigationButton.defaultProps = {
  isDisabled: false,
  ariaLabel: 'navigationButton',
  className: '',
  direction: 'left',
  navigationType: 'light',
  type: 'button',
};
