import memoize from '@travel/traveler-core/utils/memoize';

let currentId = 0;
let ids = new WeakMap<Object, number>();

export function getId(obj: Object): number {
  if (ids.has(obj)) {
    return ids.get(obj)!;
  } else {
    const newId = ++currentId;
    ids.set(obj, newId);
    return newId;
  }
}

export default memoize;
