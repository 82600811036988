import { createSelector } from 'reselect';
import { RootState } from 'typesafe-actions';

import { Groups, ProviderFilter } from 'ProviderList-Types';
import memoize from 'utils/memoize';

import { ProviderListState } from './reducer';

export const getProviderList = (state: RootState) => state.providerList;

export const getItems = createSelector(getProviderList, (state: ProviderListState) => state.items);

export const getFilters = createSelector(getProviderList, (state: ProviderListState) =>
  state.filters.sort(
    (filterA: ProviderFilter, filterB: ProviderFilter) => filterA.groupOrder - filterB.groupOrder,
  ),
);

export const getPlaceName = createSelector(
  getProviderList,
  (state: ProviderListState) => state.items.areaName,
);

export const getAreaName = createSelector(
  getProviderList,
  (state: ProviderListState) => state.areaName,
);

export const getFiltersTotal = createSelector(
  getProviderList,
  (state: ProviderListState) => state.filtersTotal,
);

export const getPlaceLayers = createSelector(
  getProviderList,
  (state: ProviderListState) => state.items.breadcrumb,
);

export const getPlaceType = createSelector(
  getProviderList,
  (state: ProviderListState) => state.items.type,
);

export const getAreaLocation = createSelector(getProviderList, (state: ProviderListState) => ({
  latitude: state.items.location?.lat || 0,
  longitude: state.items.location?.lng || 0,
  zoomLevel: state.items.location?.zoomLevel || 15,
}));

export const getErrors = createSelector(
  getProviderList,
  (state: ProviderListState) => state.errors,
);

export const getIsFetchingMore = createSelector(
  getProviderList,
  (state: ProviderListState) => state.isFetching,
);

export const getIsFetchingList = createSelector(
  getProviderList,
  (state: ProviderListState) => state.isFetchingList,
);

export const getIsFetchingFilters = createSelector(
  getProviderList,
  (state: ProviderListState) => state.isFetchingFilters,
);

export const getIsMapExpanded = createSelector(
  getProviderList,
  (state: ProviderListState) => state.isMapExpanded,
);

export const getIsFilterSelected = createSelector(
  getProviderList,
  (state: ProviderListState) => state.isFilterSelected,
);

export const getSelectedFeatures = createSelector(
  getProviderList,
  (state: ProviderListState) => state.selectedFeatures,
);

export const getSelectedObjectivesManagementName = createSelector(
  getSelectedFeatures,
  (state: Array<Groups>): Array<string> => {
    return state?.map(group => group?.managementName || '') || [];
  },
);

export const getSelectedObjectivesFeatureName = createSelector(
  getSelectedFeatures,
  (state: Array<Groups>): Array<string> => {
    return state?.map(group => group?.name || '') || [];
  },
);

export const getSelectedObjectivesFeatureIdMapping = createSelector(
  getSelectedFeatures,
  (state: Array<Groups>): { [key: string]: string } => {
    return state?.reduce((result, feature) => {
      if (!feature.managementName) {
        return result;
      }
      return { ...result, [feature.managementName?.toLowerCase()]: feature.featureId };
    }, {});
  },
);

export const getSearchFacilities = memoize((searchFilters?: string) =>
  createSelector(getProviderList, ({ filters }: ProviderListState) => {
    const filterFacilities = filters.reduce((prev: Array<Groups>, curr) => {
      if (!curr.features) {
        return prev;
      }
      return [...prev, ...curr.features];
    }, []);

    return filterFacilities
      ?.map(
        feature =>
          searchFilters?.includes(feature.featureId) && (feature.managementName || feature.name),
      )
      .filter(featureName => featureName)
      .join(',');
  }),
);

export const getScrollPosition = createSelector(
  getProviderList,
  (state: ProviderListState) => state.scrollPosition,
);

export const getRequestOffsets = createSelector(
  getProviderList,
  (state: ProviderListState) => state.pageRequestOffsets,
);
